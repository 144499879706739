/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-quotes */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import cloneDeep from 'lodash/cloneDeep';
import { CloseCircleFilled } from '@ant-design/icons';
import _ from 'lodash';
import Link from 'next/link';
// import Marquee from 'react-fast-marquee';
import { Button } from 'antd';
import { getAnnouncement } from '../../api/getAnnouncement';
import { getBrand } from '../../api/getBrand';
import { getGameCred } from '../../api/getGameCred';
import { getSportMatches } from '../../api/getSportMatches';
import {
  AviatorGif,
  BannerBtm1,
  BannerBtm2,
  BannerBtm3,
  BannerBtm4,
  BannerBtm5,
  BannerBtm7,
  BannerBtm8,
  BannerBtm9,
  bgHomeBanner,
  ChevronRight,
  DepositFail,
  DepositSuccess,
  gameDefaultImagepng,
  BannerRow21,
  BannerRow22,
  BannerRow23,
  BannerRow24,
  BannerRow41,
  BannerRow42,
  BannerRow43,
  BannerRow44,
} from '../../images/index';
import BackToTopButton from '../common/BackToTop';
import PopupModal from '../dialog/PopupModal';
import Header from '../general/Header';
import Layout from '../general/Layout';
import { SettingActions } from '../reducers/settingReducer';
import Carousel from './Carousel';
import FooterInfoSection from './FooterInfoSection';
import HomeFaq from './HomeFaq';
import HomeGamesComponent from './HomeGamesComponent';
import PartnerSection from './PartnerSection';
import SportCollapse from './SportCollapse';

function HomePage({ data }) {
  const {
    banners, popupBanners, brandIcons, hotGames, promotionPost,
  } = data;

  const router = useRouter();
  const { depositStatus } = router.query;
  const dispatch = useDispatch();
  const popUp = Cookies.get('popup');
  const isLogin = Cookies.get('accessToken');

  const refreshDate = Cookies.get('refreshDate');
  const { t } = useTranslation('common');
  const ref = useRef(null);
  const firstLoad = useRef(true);
  const isLanguage = useSelector((state) => state.setting.locale);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const windowSize = useSelector((state) => state.setting.windowSize);
  const [announcementData, setAnnouncementData] = useState('');

  const [height, setHeight] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [games, setGames] = useState([]);
  const [depositNotificationShow, setDepositNotificationShow] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');

  const [validBanners, setValidBanners] = useState([]);
  const [sportMatchesMobile, setSportMatchesMobile] = useState([]);
  const userId = Cookies.get('userId');

  const localStorageAvailable = typeof window !== 'undefined' && window.localStorage;

  const [recentlyPlayedGamesArr, setRecentlyPlayedGamesArr] = useState(
    localStorageAvailable ? JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [] : [],
  );

  const [floatingOpen, setFloatingOpen] = useState(true);
  const [popupBannersState, setPopupBannersState] = useState([]);

  // useEffect(() => {
  //   // Check if localStorage is available before using it
  //   if (localStorageAvailable) {
  //     // Retrieve from local storage on component mount
  //     const storedArray = JSON.parse(localStorage.getItem('recentlyPlayedGames')) || [];
  //     setRecentlyPlayedGamesArr(storedArray);
  //   }
  // }, [localStorageAvailable]);

  useEffect(() => {
    if (popupBanners?.length > 0) setPopupBannersState(_.sortBy(popupBanners.filter((x) => x.isVisible), ['sorting']));
  }, [popupBanners]);

  useEffect(() => {
    const handleStorageChange = () => {
      // Check if localStorage is available before using it
      if (typeof window !== 'undefined' && window.localStorage && isLogin) {
        const storedArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];
        setRecentlyPlayedGamesArr(storedArray);
      }
    };

    // Listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Retrieve from local storage on component mount
    handleStorageChange();

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const accessToSport = async (hasPath, demo) => {
    const brandInfo = await getBrand(process.env.SPORT_ID);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    if (demo) {
      router.push(`/game_website?brandId=${'658a2e8decc17606d6055c36'}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
      // router.push(`/game_website?brandId=${'658a2e8decc17606d6055c36'}&demo=${demo}`);
    } else {
      const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

      if (gameInfo?.error) {
        if (!gameInfo?.error.includes('jwt')) {
          dispatch(
            SettingActions.setAlert([
              {
                msg: gameInfo?.error,
              },
            ]),
          );
        }
        return false;
      }

      const gameProfileId = gameInfo?.data?.data?.[0]._id;
      router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
      // router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}&demo=${demo}`);
    }
  };

  const accessTo9Wicket = async () => {
    dispatch(SettingActions.setLoading(true));
    if (!Cookies.get('accessToken')) {
      return dispatch(
        SettingActions.setAlert([
          {
            msg: 'Please login',
          },
        ]),
      );
    }

    const brand9WicketId = '6656fbc78afd0a4d83d43dae';
    const brandInfo = await getBrand(brand9WicketId);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    const gameInfo = await getGameCred({ brandId: brand9WicketId });

    const gameProfileId = gameInfo?.data?.data?.[0]._id;
    router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}`);
  };

  const homeMenuList = [
    {
      key: 3,
      label: 'Aviator',
      type: '7',
      image: BannerBtm3,
      action: async () => {
        dispatch(SettingActions.setLoading(true));

        const findBrand = brandIcons?.find((x) => x.name === 'spribe');
        const findGame = findBrand?.games?.find((x) => x.name === 'Aviator');
        gotoGame({ ...findBrand, ...findGame });
      },
    },
    {
      key: 1,
      label: 'Live Casino',
      type: '8',
      image: BannerBtm1,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(1));
        router.push({
          pathname: '/live-casino',
        });
      },
    },
    {
      key: 2,
      label: 'Slots',
      type: '0',
      image: BannerBtm2,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(2));
        router.push({
          pathname: '/slots',
        });
      },
    },
    {
      key: 4,
      label: 'Card Games',
      type: '5',
      image: BannerBtm4,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/cards',
        });
      },
    },
    {
      key: 5,
      label: 'Table games',
      type: '',
      image: BannerBtm5,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/tables');
      },
    },
    // {
    //   key: 6,
    //   label: 'eSports',
    //   type: '',
    //   image: BannerBtm6,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport();
    //     } else {
    //       dispatch(SettingActions.setLoginShow(true));
    //     }
    //   },
    // },
    {
      key: 7,
      label: 'Referral Program',
      type: '',
      image: BannerBtm7,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push({
            pathname: '/referral',
          });
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 8,
      label: 'Rebate',
      type: '',
      image: BannerBtm8,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(7));
        router.push('/promotions');
      },
    },
    {
      key: 9,
      label: 'VIP',
      type: '',
      image: BannerBtm9,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          dispatch(SettingActions.setSelected(6));
          router.push('/vip');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
  ];

  const homeMenuList2 = [
    {
      key: 3,
      label: 'Telegram',
      type: '1',
      image: BannerRow21,
      action: async () => {
        window.open('https://t.me/Betleon77', '_blank');
      },
    },
    {
      key: 1,
      label: '9winket',
      type: '2',
      image: BannerRow22,
      action: () => {
        // accessTo9Wicket();
        dispatch(
          SettingActions.setAlert([
            {
              msg: 'Coming soon!',
            },
          ]),
        );
      },
    },
    {
      key: 2,
      label: 'epicWin',
      type: '0',
      image: BannerRow23,
      action: () => {
      },
    },
    {
      key: 4,
      label: 'iloveyou',
      type: '5',
      image: BannerRow24,
      action: () => {
      },
    },
  ];

  const homeMenuList3 = [
    {
      key: 3,
      label: 'promo',
      type: '0',
      image: BannerRow41,
      action: async () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/promotions',
        });
      },
    },
    {
      key: 1,
      label: 'vip',
      type: '8',
      image: BannerRow42,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push({
          pathname: '/vip',
        });
      },
    },
    {
      key: 2,
      label: 'download',
      type: '0',
      image: BannerRow43,
      action: () => {
        router.push('https://betleon.s3.ap-southeast-1.amazonaws.com/apk/BLEON77.apk');
      },
    },
    {
      key: 4,
      label: 'criket',
      type: '5',
      image: BannerRow44,
      action: () => {
        // accessTo9Wicket();
        dispatch(
          SettingActions.setAlert([
            {
              msg: 'Coming soon!',
            },
          ]),
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(SettingActions.setMobileMenuSelected(0));
  }, [data]);

  const getSportData = async () => {
    const result = await getSportMatches();

    if (result?.games?.length > 0) {
      const filterArrMobile = {};
      result?.games?.map((x) => {
        if (!filterArrMobile[x.sport_alias]) {
          filterArrMobile[x.sport_alias] = [];
        }
      });

      result?.games?.map((x) => {
        if (filterArrMobile[x.sport_alias]) {
          if (x.category_name.toLowerCase().includes('england')) {
            x.category_name = 'United Kingdom';
          } else if (x.category_name.toLowerCase().includes('usa')) {
            x.category_name = 'United States';
          }
          filterArrMobile[x.sport_alias].push(x);
        }
      });

      setSportMatchesMobile(Object.entries(filterArrMobile).map((x) => ({
        title: x[0],
        content: x[1],
      })));
    }
  };

  useEffect(() => {
    getSportData();
  }, []);

  useEffect(() => {
    if (depositStatus) {
      setPaymentStatus(depositStatus);
      setDepositNotificationShow(true);
    }
  }, []);

  useEffect(() => {
    let result = banners?.filter((i) => i.isVisible);
    result = _.sortBy(result, ['sorting']);

    setValidBanners(result);
  }, [banners, isMobile]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      for (let i = 0; i <= 3; i++) {
        if (height <= 0) {
          setTimeout(() => {
            if (ref?.current?.clientHeight) {
              setHeight(ref.current.clientHeight);
            }
          }, 500);
        } else {
          break;
        }
      }
    } else if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });

  useEffect(() => {
    const result = {
      recommendedGames: [],
      hotGames: [],
    };

    brandIcons?.map((x) => {
      x.games.map((x1) => {
        hotGames?.map((x2, i2) => {
          if ((x1._id) === x2.gameId) {
            let value = {};
            value = { ...x1 };
            value.brandThumbnail = x.imageUrl;
            value.brandName = x.name;
            value.brandId = x.brandId;
            value.thumbnail = x2.url;
            value.sorting = x2.sorting;
            value.isVisible = x2.isVisible;
            value.origin = x1;
            result.hotGames.push(value);
          } else if (x1.gameId === x2.gameId) {
            let value = {};
            value = { ...x1 };
            value.brandThumbnail = x.imageUrl;
            value.brandName = x.name;
            value.brandId = x.brandId;
            value.thumbnail = x2.url;
            value.sorting = x2.sorting;
            value.isVisible = x2.isVisible;
            value.origin = x1;
            result.hotGames.push(value);
          }
        });
      });
    });
    result.hotGames.filter((game) => game.isVisible === true).map((x) => {
      data?.brandIcons?.map((x1) => {
        if (x1.brandId === x.brandId) {
          x.brandThumbnail = x1.imageUrl;
        }
      });
    });
    setGames(result.hotGames.filter((game) => game.isVisible === true).sort((a, b) => (a.sorting || 0) - (b.sorting || 0)));
  }, [hotGames]);

  useEffect(() => {
    if (!isLogin) {
      if (popupBannersState?.length > 0) {
        if (!refreshDate) {
          // pop modal
          setShowPopup(true);
        } else if (new Date().getTime() > new Date(refreshDate).getTime()) {
          // If next day
          // check pop
          if (popUp !== true) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        }
      }
    }
    // getSocialData();
    dispatch(SettingActions.setLoading(false));
    dispatch(SettingActions.setSelected(null));
  }, [popupBannersState]);

  useEffect(() => {
    getAnnouncementData();
  }, [isLanguage]);

  const getAnnouncementData = async () => {
    const result = await getAnnouncement({ language: isLanguage });
    setAnnouncementData(result?.data || '');
  };

  const saveRecentGame = (x) => {
    const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

    const existingIndex = currentArray.findIndex((item) => item._id === x._id);

    let updatedArray;

    if (existingIndex !== -1) {
      updatedArray = [
        ...currentArray.slice(0, existingIndex),
        ...currentArray.slice(existingIndex + 1),
        x,
      ];
    } else {
      updatedArray = [...currentArray, x];
    }

    localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));
  };

  const gotoGame = async (k) => {
    if (!Cookies.get('accessToken')) {
      dispatch(SettingActions.setLoginShow(true));
    } else {
      dispatch(SettingActions.setLoading(true));
      const gameInfo = await getGameCred({
        brandId: k.brandId,
        type: k.type,
      });
      if (gameInfo?.error) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
        dispatch(SettingActions.setLoading(false));
      } else if (gameInfo) {
        saveRecentGame(k);
        router.push(
          `/game_website?brandId=${k.brandId}&gameProfileId=${gameInfo?.data?.data[0]?._id}&gameId=${k._id}`,
        );
      }
    }
  };

  return (
    <Layout selectedIcon={t('common.home')}>
      <Header data={data}>
        {/* ads */}
        <PopupModal
          show={showPopup}
          toggleShow={setShowPopup}
          info={popupBannersState}
        />

        <BackToTopButton />

        {/* Banner */}
        <div className='pt-4 px-1 md:px-8' id="top">
          <div style={{
            position: 'relative', backgroundImage: `url(${bgHomeBanner.src})`, backgroundSize: 'cover', margin: -4,
          }}
          >
            <Carousel
              id="home-banner"
              className=""
              items={(
                <>
                  {validBanners.map((i, index) => (
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          border: '5px solid rgb(237, 237, 237)',
                          width: '100%',
                          height: '100%',
                        }}
                      />

                      <div
                        key={`banner-img-b-${index}`}
                        className='leading-unset flex-item-banner cursor-pointer'
                        style={{
                          border: '5px solid rgb(237, 237, 237)',
                          borderRadius: 10,
                          width: windowSize?.width <= 768 ? 310 : 465,
                        }}
                        onClick={() => {
                          if (i.linkUrl) {
                            window.location.href = i?.linkUrl;
                          } else if (i.activeBonusId) {
                            window.location.href = `/promotions?activeBonusId=${i?.activeBonusId}`;
                          }
                        }}
                      >
                        <Image
                          ref={ref}
                          src={
                            windowSize?.width <= 768
                              ? i?.images?.en_mobile
                              : i?.images?.en
                          }
                          width={windowSize?.width <= 768 ? 320 : 465}
                          height={windowSize?.width <= 768 ? 170 : 162}
                          alt="Responsible Gambling India"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            />

            <Carousel
              id="match-1"
              className="leading-unset"
              items={(
                <>
                  {homeMenuList2.map((x) => (
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          border: '5px solid rgb(237, 237, 237)',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      <div
                        className='leading-unset flex-item-matchbanner !pl-0'
                        style={{
                          border: '5px solid rgb(237, 237, 237)',
                          borderRadius: 10,
                          width: 310,
                        }}
                        onClick={() => x.action()}
                      >
                        <Image src={x.image} alt={x.altText} />
                      </div>
                    </div>
                  ))}

                </>
              )}
            />

            <Carousel
              id="home-play"
              items={(
                <>
                  {
                    homeMenuList.map((x, i) => (
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            border: '5px solid rgb(237, 237, 237)',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                        <div
                          style={{
                            border: '5px solid rgb(237, 237, 237)',
                            borderRadius: 10,
                            width: 155,
                            height: 154,
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                          className='leading-none flex-item-playbanner cursor-pointer'
                          onClick={() => x.action()}
                        >
                          <Image src={x.image} alt={x.label} />
                          <div className='-mb-2' />
                        </div>
                      </div>
                    ))
                  }
                </>
              )}
            />

            <Carousel
              id="match-2"
              className="leading-unset b-8"
              items={(
                <>
                  {homeMenuList3.map((x) => (
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          border: '5px solid rgb(237, 237, 237)',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      <div
                        className='leading-unset flex-item-matchbanner'
                        style={{
                          border: '5px solid rgb(237, 237, 237)',
                          borderRadius: 10,
                          width: 310,
                        }}
                        onClick={() => x.action()}
                      >
                        <Image src={x.image} alt={x.altText} />
                      </div>
                    </div>
                  ))}

                </>
              )}
            />
            {/* <div style={{
              position: 'absolute', top: -1, left: -1, width: '101%', height: '101%', borderLeft: '5px solid rgb(237, 237, 237)', borderRight: '5px solid rgb(237, 237, 237)', borderTop: '2px solid rgb(237, 237, 237)', borderBottom: '5px solid rgb(237, 237, 237)', boxSizing: 'border-box', background: 'transparent', zIndex: 1, pointerEvents: 'none',
            }}
            /> */}
          </div>

          {/* {
            sportMatchesMobile?.filter((x) => x.title === 'cricket')
            && (
              <SportCollapse
                id="first-game"
                items={sportMatchesMobile?.filter((x) => x.title === 'cricket')?.[0]?.content || []}
                accessToSport={accessToSport}
              />
            )
          } */}

          <Carousel
            id="home-categories"
            className="mt-2 mb-8 bg-white rounded-lg py-4"
            items={(
              <>
                {
                  games.map((x, i) => (
                    <div
                      key={x._id}
                      className='leading-none flex-item-cat cursor-pointer ml-4'
                      onClick={() => {
                        x.thumbnail = (x?.origin?.thumbnail || x.thumbnail);
                        gotoGame(x);
                      }}
                    >
                      <Image className='mb-2' src={x.thumbnail} alt="" width={86} height={86} placeholder='empty' />
                      <p className='text-center mt-2 font-normal capitalize cut-text-full'>{x.name}</p>

                      <div className='flex items-center justify-center'>
                        <p className='text-center font-normal uppercase cut-text-game'>{x.brandName}</p>
                      </div>
                    </div>
                  ))
                }
              </>
            )}
          />

          {/* {
            sportMatchesMobile?.filter((x) => x.title !== 'cricket')
            && (
              <SportCollapse
                id="second-game"
                items={sportMatchesMobile?.filter((x) => x.title !== 'cricket')?.[0]?.content || []}
                accessToSport={accessToSport}
              />
            )
          } */}

          {recentlyPlayedGamesArr.length > 0 && (
            <div className='my-4'>
              <div className='flex justify-between mb-2'>
                <h4 className='text-base font-semibold'>Recently Played</h4>
                <div className='flex items-center'>
                  <Link
                    href='/live-casino'
                  >
                    <a
                      href='#'
                      className='font-medium mr-1'
                    >
                      More games
                    </a>
                  </Link>
                  <div className='leading-none' style={{ width: 6 }}>
                    <Image src={ChevronRight} alt='' width={6} height={9} placeholder='empty' />
                  </div>
                </div>
              </div>
              <Carousel
                id="caoursel-recently-played-game"
                className='mt-2 mb-8'
                items={(
                  <>
                    {recentlyPlayedGamesArr.toReversed()
                      .map((x, i) => (
                        <div
                          className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap flex-item-game'
                          key={`${i}-${i._id}`}
                          onClick={() => {
                            gotoGame(x);
                          }}
                        >
                          <div className='relative game-overlay-img mb-1.5'>
                            <div className='game-overlay'>
                              <Button
                                style={{
                                  background: '#28AE60',
                                }}
                                htmlType='submit'
                                className='flex bg-boxyellow rounded-full h-9 md:h-10 text-center items-center justify-center'
                              >
                                <p className='text-white font-medium text-xs md:text-sm'>
                                  Play
                                </p>
                              </Button>
                            </div>
                            <div
                              id={`skeleton-id-${i}`}
                              className='loading skeleton-gray h-24 w-full'
                            />
                            <Image

                              className='hidden'
                              src={x.thumbnail || gameDefaultImagepng?.src}
                              onLoad={(e) => {
                                document.getElementById(`skeleton-id-${i}`)?.remove();
                                e.target.classList?.remove('hidden');
                              }}
                              alt='Cricket betting sites in India'
                              layout='responsive'
                              width={140}
                              height={140}

                              placeholder='empty'
                            />
                          </div>
                          <div className='bg-white flex items-center w-full h-7 md:h-8 flex justify-center game-overlay-text px-2 -mt-1'>
                            <div className='leading-none mr-0.5' style={{ width: 22 }}>
                              <Image src={x.brandThumbnail} alt='' width={22} height={22} placeholder='empty' />
                            </div>
                            <p
                              className='leading-none text-xs font-semibold opacity-80 capitalize cut-text-game'
                            >
                              {x.brandName || x.name}
                            </p>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              />
            </div>
          )}

          <div className='my-4'>
            <div className='flex justify-between mb-2'>
              <h4 className='text-base font-semibold'>Instant Game</h4>
              <div className='flex items-center'>
                <Link
                  href='/instant-game'
                >
                  <a
                    href='#'
                    className='font-medium mr-1'
                  >
                    More games
                  </a>
                </Link>
                <div className='leading-none' style={{ width: 6 }}>
                  <Image src={ChevronRight} alt='' />
                </div>
              </div>
            </div>
            <HomeGamesComponent
              data={data}
              type={1}
              gotoGame={gotoGame}
            />
          </div>

          <div className='my-4'>
            <div className='flex justify-between mb-2'>
              <h4 className='text-base font-semibold'>Live Casino</h4>
              <div className='flex items-center'>
                <Link
                  href='/live-casino'
                >
                  <a
                    href='#'
                    className='font-medium mr-1'
                  >
                    More games
                  </a>
                </Link>
                <div className='leading-none' style={{ width: 6 }}>
                  <Image src={ChevronRight} alt='' width={6} height={9} placeholder='empty' />
                </div>
              </div>
            </div>
            <HomeGamesComponent
              data={data}
              type={8}
              gotoGame={gotoGame}
            />
          </div>

          {
            sportMatchesMobile?.filter((x) => x.title !== 'cricket').filter((x, i) => i !== 0).map((x, i) => (
              <SportCollapse
                key={x.title + i}
                id={`home-game-${i}`}
                items={x?.content || []}
                accessToSport={accessToSport}
              />
            ))
          }

          <div className='my-4'>
            <div className='flex justify-between mb-2'>
              <h4 className='text-base font-semibold'>Slots</h4>
              <div className='flex items-center'>
                <Link
                  href='/slots'
                >
                  <a
                    href='#'
                    className='font-medium mr-1'
                  >
                    More games
                  </a>
                </Link>
                <div className='leading-none' style={{ width: 6 }}>
                  <Image src={ChevronRight} alt='' width={6} height={9} placeholder='empty' />
                </div>
              </div>
            </div>
            <HomeGamesComponent
              data={data}
              type={0}
              gotoGame={gotoGame}
            />
          </div>

          {/* <div className='my-4'>
            <div className='flex justify-between mb-2'>
              <h4 className='text-base font-semibold'>Bingo</h4>
              <div className='flex items-center'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    router.push('/slots');
                  }}
                  className='font-medium mr-1'
                >
                  More games
                </a>
                <div className='leading-none' style={{ width: 6 }}>
                  <Image   src={ChevronRight} alt='' />
                </div>
              </div>
            </div>
            <HomeGamesComponent
              id="6304a15e3eaa04045646e8c0"
              data={data}
              type={0}
              gotoGame={gotoGame}
            />
          </div> */}

          <FooterInfoSection />
          <HomeFaq />
        </div>
      </Header>
      <div className='relative mx-auto'>
        <div className='mb-auto col-span-12 md:col-span-10 h-full'>
          <PartnerSection />
        </div>
      </div>

      {/* deposit status */}
      <div className={`${depositNotificationShow ? '' : 'hidden'}`}>
        <div
          tabIndex='-1'
          className='md:p-10 overflow-scroll bg-black bg-opacity-60 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          role='dialog'
        >
          <div
            className='overlay-closeable'
            onClick={() => setDepositNotificationShow(false)}
          />
          <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
            <div className='mx-5'>
              <div className='flex justify-end'>
                <button onClick={() => setDepositNotificationShow(false)}>
                  <CloseCircleFilled
                    fill='white'
                    style={{ fontSize: '20px' }}
                  />
                </button>
              </div>
              <div className='mb-5 text-lg gap-6 font-medium text-white flex flex-col justify-center items-center'>
                {paymentStatus === 'success' ? (
                  <Image alt='Deposit Sucess' src={DepositSuccess} width={60} height={60} placeholder='empty' />
                ) : (
                  <Image alt='Deposit Fail' src={DepositFail} width={60} height={60} placeholder='empty' />
                )}
                <p>
                  Payment
                  {paymentStatus}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* campaign modal */}
      <div
        className={`bottom-14 md:bottom-0 z-50 fixed campaign-pool-wrapper ${floatingOpen ? 'md:block' : 'hidden'
        }`}
      >
        <div className='flex flex-row cursor-pointer '>
          <div style={{ width: isMobile ? 140 : 180 }}>
            <Image

              src={AviatorGif}
              onClick={() => {
                router.push('/aviator');
              }}
              width={180}
              height={180}

              alt=""
              placeholder='empty'
            />
          </div>
          <div>
            <CloseCircleFilled
              fill='black'
              style={{ fontSize: '16px', color: 'black' }}
              onClick={() => {
                setFloatingOpen(!(floatingOpen));
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;
